import { ascendantNode } from '@avvoka/editor'
import { clone } from "@avvoka/shared";
import Axios from 'axios'
import { animVerticalScrollTo } from './_abstract/utils/scroll'

export default class Utils {
  public static get axios() {
    Axios.defaults.headers.common['X-CSRF-Token'] = Utils.CSRFToken
    Axios.interceptors.response.use(
      function (response) {
        window.localStorage.avvokaLastRequest = Date.now()
        return response
      },
      function (error) {
        window.localStorage.avvokaLastRequest = Date.now()
        return Promise.reject(error)
      }
    )
    return Axios
  }

  public static DIVIDER_TOKEN ='#='

  public static get CSRFToken() {
    return document
      .getElementsByName('csrf-token')?.[0]
      ?.getAttribute('content') as string
  }

    public static removeNonBreakingSpaces(str: string) {
        return str.replace(/\u200B/g,'').replace(/&#8203;/g, '')
    }

  public static fileIcon(fileType: string) {
    switch (fileType) {
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
      case 'text/plain':
        return 'description'
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel.template.macroEnabled.12':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
        return 'backup_table'
      case 'application/pdf':
        return 'picture_as_pdf'
      case 'application/zip':
        return 'folder_zip'
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'slide_library'
      case 'application/vnd.ms-outlook':
      case 'message/rfc822':
        return 'mail'
      default:
        if (fileType?.startsWith('image')) {
          return 'image'
        }
        return 'draft'
    }
  }

  /**
   * Deep copy an object
   * @deprecated Use clone from '@avvoka/shared' instead
   * @param value
   */
  public static deepCopy<T>(value: T): T {
    return clone(value)
  }

  public static highlightNode(node: Node, flashDelay: number) {
    ;(node as HTMLElement).style.setProperty(
      '--flashing-interval',
      flashDelay + 'ms'
    )
    setTimeout(() => {
      ;(node as HTMLElement).classList.toggle(`fade-out-highlight`, true)
      setTimeout(
        () =>
          (node as HTMLElement).classList.toggle(`fade-out-highlight`, false),
        flashDelay
      )
    })
  }

  public static scrollNodeIntoView(
    node: HTMLElement,
    scroll: HTMLElement = ascendantNode(
      node,
      (node) => node.nodeName === 'AVV-CONTAINER' || node === window
    ) as HTMLElement,
    duration = 500,
    opts?: { onUpdate?: VoidFunction; onDone?: VoidFunction }
  ) {
    animVerticalScrollTo(
      scroll,
      node.getBoundingClientRect().top +
        scroll.scrollTop -
        scroll.getBoundingClientRect().top,
      duration,
      undefined,
      opts
    )
  }

  public static notANumber(arg: any): boolean {
    return typeof arg !== 'number' || isNaN(arg)
  }

  public static stringifyValues(object: any): any {
    if (object === null || object === undefined) {
      return object
    } else if (object instanceof Array) {
      return object.map((value: any) => Utils.stringifyValues(value))
    } else {
      return String(object)
    }
  }
}

export const initializeAfter = (
  body: VoidFunction,
  interval: number,
  stopCondition: () => boolean
) => {
  const intervalId = setInterval(() => {
    if (stopCondition()) {
      clearInterval(intervalId)
      body()
    }
  }, interval)
}
if (typeof window !== 'undefined') {
  window.initializeAfter = initializeAfter
}
